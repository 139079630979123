import { toggleSubmit } from '../util/button';

export default {
    init() {

        console.log('Loaded checkout');

        // Switch views
        let $checkoutContinueBtn = $('#js-order-confirmation');
        $checkoutContinueBtn.click(function(e) {
            e.preventDefault();

            // Validate fields
            let errors      = [];

            // Check required
            let required = $(this).parents('form').find('.form-row.validate-required');
            $.each(required, function() {

                let $input = $(this).find('.input-text');
                if (!$input.length) {
                    $input = $(this).find('select.select');
                }

                // Validate this section?
                let doValidate = true;
                if (!$('#ship-to-different-address-checkbox').is(':checked')) {
                    if ($input.parents('div').hasClass('shipping_address')) {
                        doValidate = false;
                    }
                }

                $input.focus();

                if ($input.length && ($input.val() == '') && doValidate) {

                    $input.addClass('has-error');

                    if ($input.attr('data-qa-id') !== undefined) {
                        errors.push('Field ' + $input.attr('data-qa-id') + ' is required');
                    } else {
                        let fieldLabel = $input.parents('p').find('label').html();
                        fieldLabel.replace('*', '');
                        errors.push('Field ' + fieldLabel + ' is required.');
                    }

                } else {
                    $input.removeClass('has-error');
                }

                let $select = $(this).find('.country_select');
                if ($select.length && $select.val() == '') {
                    $select.addClass('has-error');
                    errors.push('County must be selected.');
                } else {
                    $select.removeClass('has-error');
                }
            });

            // Phone validation
            let phones = $('#billing_phone, #delivery_phone');
            $.each(phones, function() {
                if ($(this).val().length < 9 || $(this).val().length > 14 || !$(this).val().match(/^\d+$/)) {
                    $(this).addClass('has-error');
                    errors.push('Invalid phone number.');
                }
            });

            // Email validation
            if (!$('#billing_email').val().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                $('#billing_email').addClass('has-error');
                errors.push('Invalid email address.');
            }

            // List the errors
            let $formErrors = $('#form-errors');
            if (errors.length) {

                $formErrors.html('<strong>Form Errors</strong>');
                $.each(errors, function(i, error) {
                    $formErrors.append('<p>' + error + '</p>');
                });
                $formErrors.removeClass('d-none');

                $('html, body').animate({
                    scrollTop: $('#order-details').offset().top,
                },500);

            } else {
                $formErrors.html('');
                $formErrors.addClass('d-none');
            }

            // No errors? Continue
            if (!errors.length) {
                $('#order-details').addClass('d-none');
                $('#order-confirmation').removeClass('d-none');
                $('#checkout-before').removeClass('d-none');

                $('html,body').scrollTop(0);
            }

        });

        $('#js-enter-details').click(function(e) {
            e.preventDefault();

            $('#order-details').removeClass('d-none');
            $('#order-confirmation').addClass('d-none');
            $('#checkout-before').addClass('d-none');

            $('html,body').scrollTop(0);
        });

        // Check final checkout button
        let placeOrderBtn = document.getElementById('place_order_final');
        let termsCheckbox = document.getElementById('agree-terms-conditions');
        function checkTerms() {
            if (placeOrderBtn && termsCheckbox) {
                if (!termsCheckbox.checked) {
                    placeOrderBtn.classList.add('disabled');
                } else {
                    placeOrderBtn.classList.remove('disabled');
                }
            }
        }
        if (termsCheckbox) {
            termsCheckbox.addEventListener('change', function() {
                checkTerms();
            });
        }
        checkTerms();

        // Hide/show checkout container
        let deliveryCheckbox        = document.getElementById('ship-to-different-address-checkbox');
        let deliverySection         = document.getElementById('delivery');
        if (deliveryCheckbox && deliverySection) {

            let deliverySectionFields = deliverySection.querySelectorAll('.shipping_address')[0];

            // On checkbox changes
            deliveryCheckbox.addEventListener('change', function() {
                if (deliveryCheckbox.checked) {
                    deliverySectionFields.classList.remove('d-none');

                    // Find all required fields and set the validate class
                    let requiredFields = deliverySectionFields.querySelectorAll('.form-row[data-do-validate="true"]');
                    if (requiredFields.length) {
                        requiredFields.forEach(function(inputRow) {
                            inputRow.classList.add('validate-required');
                        });
                    }
                } else {
                    deliverySectionFields.classList.add('d-none');

                    // Find all required fields
                    let requiredFields = deliverySectionFields.querySelectorAll('.form-row.validate-required');
                    if (requiredFields.length) {
                        requiredFields.forEach(function(inputRow) {
                            inputRow.classList.remove('validate-required');
                            inputRow.setAttribute('data-do-validate', true);
                        });
                    }
                }

            });

            // On load, if delivery address different is not ticked, remove the validate required class
            let requiredFields = deliverySectionFields.querySelectorAll('.form-row.validate-required');
            if (requiredFields.length) {
                requiredFields.forEach(function(inputRow) {
                    inputRow.classList.remove('validate-required');
                    inputRow.setAttribute('data-do-validate', true);
                });
            }
        }

        // Show coupon section
        $('.showcoupon').click(function(e) {
            e.preventDefault();
            $(this).parents('.checkout__before').addClass('open');
        });


        // Checkout authentication page
        document.querySelectorAll('#guest_account_form, #create_account_form').forEach(function(form) {

            let submitForm = false;
            form.addEventListener('submit', function(e) {

                if (!submitForm) {
                    e.preventDefault();
                }

                let btn = form.querySelector('button[type="submit"]');
                toggleSubmit(btn);

                let email = form.querySelector('input[type="email"]');
                if (!email) {
                    return console.error('Email field not found.');
                }

                // Check if email exists as a user.
                fetch('/wp-json/users/check_email_exists', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        email: email.value,
                    }),
                })
                .then((response) => response.json())
                .then((data) => {

                    toggleSubmit(btn);

                    if (data.email_in_use) {
                        let errorMessage = document.createElement('ul');
                        errorMessage.classList.add('errors');
                        errorMessage.innerHTML = '<li>Email address is already in use. Please login.</li>';
                        email.parentElement.appendChild(errorMessage);

                        return false;
                    }

                    submitForm = true;
                    form.submit();
                });
            });
        });


    },
    finalize() {
        // JavaScript to be fired on the checkout, after the init JS
    },
};
