import $ from 'jquery';

$(function() {

    // Init the datalayer if it hasn't already
    window.dataLayer = window.dataLayer || [];

    // Track product click
    $(document).on('click', '.js-tc', function(e) {
        e.preventDefault();

        let currency = $(this).attr('data-currency');
        let productData = $(this).attr('data-product-data');
        let product = JSON.parse(productData);

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'productClick',
            'currencyCode': currency,
            'ecommerce': {
                'click': {
                    'products': [{
                        'name': product.name,
                        'id': product.sku,
                        'price': product.price,
                        'category': product.category,
                        'position': product.position,
                    }],
                },
            },
        });

        // Go to original url
        let url = $(this).attr('href');
        location.href = url;
    });

    // Add to basket button
    $(document).on('click', 'button[name="add-to-cart"]', function() {

        let productData = $(this).attr('data-product-data');
        let product = JSON.parse(productData);
        var quantity = $(this).prev('.quantity').find('input').val();

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'GBP',
                'add': {
                    'products': [{
                        'name': product.name,
                        'id': product.sku,
                        'price': product.price,
                        'category': product.category,
                        'quantity': quantity,
                    }],
                },
            },
        });
    });

    // Add donation to cart
    $(document).on('click', 'button[name="update_cart"]', function() {

        window.dataLayer.push({ ecommerce: null });
        let productToAdd = [];
        let productToRemove = [];

        // Loop products
        $('.woocommerce-cart-form__contents').each(function() {

            let name = $(this).find('.product-name a').html();
            let price = $(this).find('.product-price bdi').html();
            let quantity = $(this).find('.input-text.qty').val();
            let sku = $(this).find('.item-sku').html();

            if (quantity == 0) {

                productToRemove.push({
                    name: name,
                    id: sku,
                    price: price,
                    quantity: quantity,
                });

            } else {
                productToAdd.push({
                    name: name,
                    id: sku,
                    price: price,
                    quantity: quantity,
                });
            }

        });

        window.dataLayer.push({ ecommerce: null });

        // Products to remove
        if (productToRemove.length) {
            window.dataLayer.push({
                'event': 'removeFromCart',
                'ecommerce': {
                    'currencyCode': 'GBP',
                    'remove': {
                        'products': productToRemove,
                    },
                },
            });
        }

        // Product to add
        if (productToAdd.length) {
            window.dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': 'GBP',
                    'add': {
                        'products': productToAdd,
                    },
                },
            });
        }
    });

    // Remove from cart button
    $(document).on('click', '.product-remove .remove', function() {

        let name = $(this).parents('tr').find('.product-name a').html();
        let price = $(this).parents('tr').find('.product-price bdi').html();
        let quantity = $(this).parents('tr').find('.input-text.qty').val();
        let sku = $(this).parents('tr').find('.item-sku').html();

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
                'currencyCode': 'GBP',
                'remove': {
                    'products': [{
                        'name': name,
                        'id': sku,
                        'price': price,
                        'quantity': quantity,
                    }],
                },
            },
        });
    });

});