
/**
 * Toggle loading button
 */
export function toggleSubmit(button){

    if (!button) {
        return console.error('Button not found.')
    }

    if (button.classList.contains('submitting')) {
        button.classList.remove('submitting')
        button.textContent = button.getAttribute('data-initial-html');
        button.setAttribute('data-initial-html', '');

        if (button.querySelector('span.icon--loading')) {
            button.querySelector('span.icon--loading').classList.remove('show');
        }

    } else {
        button.classList.add('submitting')
        button.setAttribute('data-initial-html', button.textContent);

        button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle></svg>';
    }
}