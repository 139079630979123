/* eslint-disable */
import * as Klaro from 'klaro/dist/klaro-no-translations-no-css';
import LazyLoad from 'vanilla-lazyload';

import { CountUp } from 'countup.js';

import Swiper from 'swiper';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';
SwiperCore.use([Pagination, Navigation, Autoplay]);

import { Modal } from 'bootstrap';
import { Loader } from '@googlemaps/js-api-loader';
import 'select2';

export default {
    init() {

        // Hero carousel
        new Swiper('.hero-swiper', {
            slidesPerView: 1,
            spaceBetween: 0,
            direction: 'horizontal',
            autoplay: {
                delay: 5000,
            },
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
                clickable: true
            },
            startSwip(event) {
                event.target.swiper.autoplay.start();
            },
        });

        // Key stats carousel
        let columnSlider;
        let gridSlider;
        let donationSlider;

        // Check breakpoints for sliders
        const tabPortraitBreakpoint = window.matchMedia('(min-width: 768px)');
        const breakpointChecker = function() {
            if (tabPortraitBreakpoint.matches === true) {

                console.log('Destroy sliders if they exist');

                if ( columnSlider !== undefined ) {
                    if (Array.isArray(columnSlider)) {
                        columnSlider.forEach(function(swiper) {
                            swiper.destroy( false, true );
                        });
                    } else {
                        columnSlider.destroy( false, true );
                    }
                    columnSlider = undefined;
                }
                if ( gridSlider !== undefined ) {
                    if (Array.isArray(gridSlider)) {
                        gridSlider.forEach(function(swiper) {
                            swiper.destroy( false, true );
                        });
                    } else {
                        gridSlider.destroy( true, true );
                    }
                    gridSlider = undefined;
                }


                if ( donationSlider !== undefined ) {
                    if (Array.isArray(donationSlider)) {
                        donationSlider.forEach(function(swiper) {
                            swiper.destroy( true, true );
                        });
                    } else {
                        donationSlider.destroy( true, true );
                    }
                    donationSlider = undefined;
                }
                return;
            } else {
                console.log('Render sliders');
                renderSliders();
            }
        }
        tabPortraitBreakpoint.addListener(breakpointChecker);
        breakpointChecker();

        // Render sliders
        function renderSliders() {
            if (document.querySelectorAll('.column-slider').length) {
                columnSlider = new Swiper('.column-slider', {
                    slidesPerView: 1,
                    spaceBetween: 50,
                    centeredSlides: true,
                    enabled: true,
                    loop: true,
                    pagination: {
                        el: '.column-slider-pagination',
                        dynamicBullets: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 50,
                        },
                    },
                });
            }

            if (document.querySelectorAll('.grid-slider').length) {
                gridSlider = new Swiper('.grid-slider', {
                    slidesPerView: 1,
                    spaceBetween: 50,
                    centeredSlides: true,
                    enabled: true,
                    loop: true,
                    autoplay: {
                        delay: 5000,
                    },
                    pagination: {
                        el: '.grid-slider-pagination',
                        dynamicBullets: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 50,
                        },
                    },
                });
            }

            if (document.querySelectorAll('.donation-swiper').length) {
                donationSlider = new Swiper('.donation-swiper', {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    centeredSlides: true,
                    enabled: true,
                    initialSlide: 2,
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-donation-button--next',
                        prevEl: '.swiper-donation-button--prev',
                    },
                    on: {
                        slideChange: function() {
                            setTimeout(function() {
                                $('.swiper-slide-active a').trigger('click');
                            }, 250);
                        },
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                    },
                });
            }
        }

        // Lazy images
        function loadLazy() {
            var lazyImages = new LazyLoad({
                elements_selector: '.lazyload',
            });
            lazyImages.update();
        }
        loadLazy();

        // Auto count up
        var observerOfStats;
        if ('IntersectionObserver' in window) {
            observerOfStats = new IntersectionObserver(function(entries) {
                entries.forEach(function(entry) {
                    if ((entry.intersectionRatio > 0) && (!entry.target.classList.contains('loaded'))) {
                        let targetNum = parseInt(entry.target.getAttribute('data-target-number'));
                        let numAnim = new CountUp(entry.target, targetNum, {
                            startVal: (targetNum / 10),
                            useEasing: true,
                        });
                        numAnim.start();
                        entry.target.classList.add('loaded');
                    }
                });
            }, { threshold: [0.5] });
            document.querySelectorAll('.auto-count-up').forEach(function (figure) {
                observerOfStats.observe(figure);
            });
        } else {
            document.querySelectorAll('.auto-count-up').forEach(function (figure) {
                figure.innerHTML = figure.getAttribute('data-target-number');
            });
        }
        // Auto count up


        // JavaScript to be fired on all pages
        window.klaro = Klaro;
        // we set up Klaro with the config
        Klaro.setup(window.klaroConfig);

        // Opening the mobile menu
        $('#menu-button').on('click', function () {
            $('#menu-button').toggleClass('open');
            $( 'html, body' ).toggleClass( 'mobile-menu-open' );

            var text = $('#menu-button').attr('aria-label');
            if(text == 'Open mobile navigation menu') {
                $('#menu-button').attr('aria-label', 'Close mobile navigation menu')
                $('#menu-button').attr('aria-expanded', true)
            } else {
                $('#menu-button').attr('aria-label', 'Open mobile navigation menu')
                $('#menu-button').attr('aria-expanded', false)
            }
        });

        /*window.addEventListener('scroll', function(e) {
            if (document.body.classList.contains('mobile-menu-open')) {
                window.scrollTo(0, 0);
            }
        });*/
        // Opening the mobile menu

        $('nav .menu-item-has-children > a').on('click', function (event) {
            if (document.body.clientWidth < 992) {
                event.preventDefault();

                let $subMenu = $(this).siblings('ul.sub-menu');

                if( $subMenu.find('.mobmenu-back').length === 0 ) {
                    $subMenu.prepend('<span class="mobmenu-header"><a href="'+ $(this).parent('li').find('a').attr('href') +'">'+ $(this).parent('li').find('a').html() +'</a></span>');

                    var icon = '<svg xmlns="http://www.w3.org/2000/svg" width="25.291" height="13.956" viewBox="0 0 25.291 13.956"><g transform="translate(630.174 654.463) rotate(180)"><g transform="translate(607 640.528)"><line x2="20.425" transform="translate(-2.116 6.953)" fill="none" stroke="#fff" stroke-width="2.42"/><path d="M643.319,641.529l10.541,6.008-10.541,5.838" transform="translate(-633.155 -640.499)" fill="none" stroke="#fff" stroke-width="2.42"/></g></g></svg>';
                    $subMenu.append('<span class="mobmenu-back"><span>'+ icon +' Back</span></span>');

                    $subMenu.wrapInner('<div class="sub-menu-align"></div>');

                    if ($subMenu.find('> .sub-menu-align > li').length > 6) {
                        $subMenu.addClass('scroll-helper');
                    }

                }
                $(this).parent().toggleClass('show');
                $(this).next().toggleClass('show');
            }
        });

        // Append menu structure on desktop
        $('nav.navigation--main ul.nav--main > li.menu-item-has-children').each(function() {

            // Get items
            var $menuItem = $(this);
            var $subItems = $menuItem.find('.sub-menu');

            // Does the sub-menu have child items
            if ($subItems.find('li.menu-item-has-children').length) {

                $menuItem.addClass('has-multiple-levels');

                // Create a collection of bottom level menu items
                var subMenuItemMenus = '';
                $menuItem.find('.sub-menu li').each(function() {
                    if ($(this).find('.sub-menu').length) {
                        subMenuItemMenus += '<ul id="'+ $(this).attr('id') +'-child">'+ $(this).find('.sub-menu').html() +'</ul>';
                    }
                });

                // Build new dekstop menu
                var desktopMenu = '<div class="sub-menu-desktop"><div class="left"><ul>' + $subItems.html() + '</ul></div><div class="right">' + subMenuItemMenus + '</div></div>';
                $(this).append(desktopMenu);

                // Add special class to menu items with third level children
                $(this).find('.sub-menu > li').each(function() {
                    $(this).find('a').addClass('show-third-level');
                });

                // Set first menu item to active
                $(this).find('.left ul li:first-child').addClass('active');
                $(this).find('.right ul:first-child').addClass('show');

                // Create standard width on all sub menus
                correctMenuWidth($menuItem);
            }
        });

        // Trigger max width when window is resized
        $(window).resize(function() {
            $('nav.navigation--main ul.nav--main > li.menu-item-has-children').each(function() {
                var $menuItem = $(this);
                if ($menuItem.find('li.menu-item-has-children').length) {
                    correctMenuWidth($menuItem);
                }
            });
        });

        // Create standard width on all sub menus
        function correctMenuWidth(menuItem) {

            // Reset widths
            $(menuItem).find('.sub-menu-desktop ul').width('initial');

            // Left
            let maxSubMenuLeftUlWidth = 0;
            $(menuItem).find('.sub-menu-desktop .left li').each(function() {
                if ($(this).width() > maxSubMenuLeftUlWidth) {
                    maxSubMenuLeftUlWidth = $(this).width()
                }
            });
            $(menuItem).find('.sub-menu-desktop .left ul').css('width', maxSubMenuLeftUlWidth + 'px');

            // Right
            let maxSubMenuRightUlWidth = 0;
            $(menuItem).find('.sub-menu-desktop .right ul').each(function() {
                if ($(this).width() > maxSubMenuRightUlWidth) {
                    maxSubMenuRightUlWidth = $(this).width();
                }
            });
            $(menuItem).find('.sub-menu-desktop .right ul').css('width', maxSubMenuRightUlWidth + 'px');
        }


        // Show the third level menu on mobile
        $(document).on('click', '.show-third-level', function() {
            $('.nav-mobile-toolbar > .icon--cart').addClass('icon--cart-dark');
        });

        // Go back in mobile menu
        $(document).on('click', '.mobmenu-back', function() {
            $('.nav-mobile-toolbar > .icon--cart').removeClass('icon--cart-dark');
        });

        $(document).on('mouseover', '.sub-menu-desktop > .left > ul > li', function() {

            $(this).parents('.sub-menu-desktop').find('.right ul.show').removeClass('show');
            $(this).parents('ul').find('li.active').removeClass('active');

            var itemId = $(this).attr('id');
            var childMenuId = '#' + itemId + '-child';

            $(childMenuId).addClass('show');
            $(this).addClass('active');

        });

        $('nav').on('click', '.mobmenu-back', function () {
            $(this).parent().closest('ul').toggleClass('show');
            $(this).next().toggleClass('show');

            $(this).closest('a.dropdown-toggle').toggleClass('show');
            $(this).closest('ul.dropdown-menu').toggleClass('show');
        });
        // Opening the header menu dropdowns

        // Search bar
        $(document).on('click', '.js-open-search', function (e) {
            e.preventDefault();

            if ($(this).parents('li.item--search').hasClass('open')) {
                $('li.item--search').parents('ul').addClass('search-open');
                $('li.item--search').addClass('close').removeClass('open');
                $('li.item--account').removeClass('open');
            } else {
                $('li.item--search').removeClass('close').addClass('open');
                $('li.item--search').parents('ul').removeClass('search-open');
                $('li.item--search').find('.search-field').focus();
                $('li.item--account').addClass('open');
            }
        });

        // Detect elements coming into view and add class
        const ivElements = document.querySelectorAll('.iv');

        if ('IntersectionObserver' in window) {
            var observer;
            observer = new IntersectionObserver(function(entries) {
                entries.forEach(function(entry) {
                    if (entry.intersectionRatio > 0) {
                        entry.target.classList.add('iv-loaded');
                    }
                });
            }, { threshold: [0.5] });

            ivElements.forEach(function(image) {
                observer.observe(image);
            });
        } else {
            ivElements.forEach(function(entry) {
                entry.classList.add('iv-loaded');
            });
        }


        // Detect elements coming into view and add class

        // Initialize Google maps
        let cloudConsoleKey = document.querySelector('meta[name="google-cloud-console-key"]').content;
        const loader = new Loader({
            apiKey: (cloudConsoleKey ? cloudConsoleKey : null),
            version: 'weekly',
            libraries: ["places"]
        });

        // Initialize and add the map
        let locationMap     = document.getElementById('location-map');
        let gLocationMap    = null;
        if (locationMap) {
            loader.load().then((google) => {

                let mapStyles = [
                    {
                        'featureType': 'all',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#6C747D',
                            },
                        ],
                    },
                    {
                        'featureType': 'all',
                        'elementType': 'labels.text.stroke',
                        'stylers': [
                            {
                                'visibility': 'on',
                            },
                            {
                                'color': '#13263C',
                            },
                            {
                                'weight': 2,
                            },
                            {
                                'gamma': '1',
                            },
                        ],
                    },
                    {
                        'featureType': 'all',
                        'elementType': 'labels.icon',
                        'stylers': [
                            {
                                'visibility': 'off',
                            },
                        ],
                    },
                    {
                        'featureType': 'administrative',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'weight': 0.6,
                            },
                            {
                                'color': '#223347',
                            },
                            {
                                'gamma': '0',
                            },
                        ],
                    },
                    {
                        'featureType': 'administrative.neighborhood',
                        'elementType': 'labels.text',
                        'stylers': [
                            {
                                'visibility': 'off',
                            },
                        ],
                    },
                    {
                        'featureType': 'landscape',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#223347',
                            },
                            {
                                'gamma': '1',
                            },
                            {
                                'weight': '10',
                            },
                        ],
                    },
                    {
                        'featureType': 'poi',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#2F3F51',
                            },
                        ],
                    },
                    {
                        'featureType': 'poi.attraction',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#4E596B',
                            },
                        ],
                    },
                    {
                        'featureType': 'poi.business',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#4E596B',
                            },
                        ],
                    },
                    {
                        'featureType': 'poi.government',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#4E596B',
                            },
                        ],
                    },
                    {
                        'featureType': 'poi.medical',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#4E596B',
                            },
                        ],
                    },
                    {
                        'featureType': 'poi.park',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#283B51',
                            },
                        ],
                    },
                    {
                        'featureType': 'poi.park',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#4E596B',
                            },
                        ],
                    },
                    {
                        'featureType': 'poi.place_of_worship',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#4E596B',
                            },
                        ],
                    },
                    {
                        'featureType': 'poi.school',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#4E596B',
                            },
                        ],
                    },
                    {
                        'featureType': 'poi.sports_complex',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#4E596B',
                            },
                        ],
                    },
                    {
                        'featureType': 'road',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#182B40',
                            },
                            {
                                'lightness': '0',
                            },
                        ],
                    },
                    {
                        'featureType': 'road',
                        'elementType': 'labels.text.fill',
                        'stylers': [
                            {
                                'color': '#4E596B',
                            },
                        ],
                    },
                    {
                        'featureType': 'transit',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#304358',
                            },
                        ],
                    },
                    {
                        'featureType': 'water',
                        'elementType': 'geometry',
                        'stylers': [
                            {
                                'color': '#0E2239',
                            },
                        ],
                    },
                ];

                gLocationMap = new google.maps.Map(locationMap, {
                    zoom: 6,
                    center: { lat: 54.315096, lng: -2.096246 },
                    styles: mapStyles,
                    disableDefaultUI: true,
                    zoomControl: true,
                });
            });
        }

        // Render all accordion maps
        loader.load().then((google) => {

            let accordionMaps = document.querySelectorAll('.accordion-item-map');
            if (accordionMaps.length > 0) {
                accordionMaps.forEach(function(item) {
                    let lat = parseFloat(item.getAttribute('data-lat'));
                    let lng = parseFloat(item.getAttribute('data-lng'));
                    let address = parseFloat(item.getAttribute('data-address'));

                    if (!lat || !lng) {
                        console.error('Missing lat or lng. Cannot render map.');
                        return;
                    }

                    let mapContainer = document.getElementById(item.getAttribute('id'));
                    let map = new google.maps.Map(mapContainer, {
                        zoom:  15,
                        center: { lat: lat, lng: lng },
                    });

                    let marker = new google.maps.Marker({
                        position: { lat: lat, lng: lng },
                        title: address,
                    });

                    marker.setMap(map);
                });
            }


            // Render map markers - On load
            if (locationMap) {
                if (locationMap.getAttribute('data-type') == 'courses') {
                    getCoursesForMap();
                } else if (locationMap.getAttribute('data-type') == 'events') {
                    getEventsForMap();
                } else if (locationMap.getAttribute('data-type') == 'directory') {
                    getDirectoryListingsForMap();
                }
            }

            // Search events
            let locationMarkers = [];
            let locationSearch = document.getElementById('location-search-form');
            if (locationSearch) {
                locationSearch.addEventListener('submit', (e) => {
                    e.preventDefault();

                    if (locationMap.classList.contains('loading')) {
                        return;
                    }
                    locationMap.classList.toggle('loading');

                    let keywords = locationSearch.querySelector('input[name="location"]');
                    if (!keywords) {
                        console.error('Missing location input');
                        return;
                    }

                    return getEventsForMap(keywords.value);

                });
            }

            function getEventsForMap (location) {
                let url = '/wp-json/events/v1/get/?return_data_only=0&type=tour&start=&end=';

                if (location) {
                    url += '&location=' + location;
                }

                fetch(url, {
                    method: 'GET',
                })
                .then(response => response.json())
                .then(data => {

                    if (data.success && data.result) {
                        for (let i = 0; i < data.result.length; i++) {
                            let event = data.result[i];

                            if (isNaN(event.latitude) || isNaN(event.longitude)) {
                                continue;
                            }

                            let marker = new google.maps.Marker({
                                position: { lat: event.latitude, lng: event.longitude },
                                title: event.post_title,
                                animation: google.maps.Animation.DROP,
                                icon: {
                                    path: 'M19.851,57.81C3.108,33.537,0,31.046,0,22.125a22.125,22.125,0,1,1,44.25,0c0,8.921-3.108,11.412-19.851,35.685a2.767,2.767,0,0,1-4.548,0Z',
                                    fillColor: '#ea8a4b',
                                    fillOpacity: 1,
                                    anchor: new google.maps.Point(20, 60),
                                    strokeWeight: 1,
                                    strokeColor: '#ffffffaa',
                                    scale: 0.7,
                                    data: event,
                                },
                            });

                            marker.addListener('click', () => {
                                showEventModal (event);
                            });

                            marker.setMap(gLocationMap);
                            locationMarkers.push(marker);
                        }
                    }

                    // Zoom map
                    if (data.coordinates && location) {
                        gLocationMap.panTo(data.coordinates);
                        gLocationMap.setZoom(10);
                    }
                })
                .then(function() {
                    locationMap.classList.remove('loading');
                });
            }

            // Search courses
            let courseLocationSearch = document.getElementById('course-location-search-form');
            if (courseLocationSearch) {
                courseLocationSearch.addEventListener('submit', (e) => {
                    e.preventDefault();

                    let keywords = courseLocationSearch.querySelector('input[name="location"]');
                    if (!keywords && !keywords.value) {
                        console.error('Missing location input');
                        return;
                    }

                    return getCoursesForMap(keywords.value);
                });
            }


            // Fetch courses for map
            function getCoursesForMap(location) {
                let url = '/wp-json/courses/v1/get/';

                if (location) {
                    url += '?location=' + location;
                }

                fetch(url, {
                    method: 'GET',
                })
                .then(response => response.json())
                .then(data => {

                    if (data.success && data.result) {
                        let latitudes = [];
                        let longitudes = [];
                        let towncity = [];

                        // get a list of locations
                        for (let i = 0; i < data.result.length; i++) {
                            latitudes.push(data.result[i].latitude);
                            longitudes.push(data.result[i].longitude);
                            towncity.push(data.result[i].towncity);
                        }

                        for (let i = 0; i < data.result.length; i++) {
                            let course = data.result[i];

                            if (isNaN(course.latitude) || isNaN(course.longitude)) {
                                continue;
                            }

                            const duplicateTownCity = getIndexesOfValue(towncity, course.towncity);
                            //const duplicateLatitudes = getIndexesOfValue(latitudes, course.latitude);
                            //const duplicateLongitudes = getIndexesOfValue(longitudes, course.longitude);

                            // if this is a duplicate location
                            //if (duplicateLatitudes.length > 1 && duplicateLongitudes.length > 1) {
                            if (duplicateTownCity.length > 1) {

                                // skip if this isn't the first of this duplicate location
                                if (duplicateTownCity[0] !== i) {
                                    continue;
                                }

                                let courses = [];

                                // get courses with this location
                                for (let j = 0; j < duplicateTownCity.length; j++) {
                                    if (duplicateTownCity.includes(duplicateTownCity[j])) {
                                        courses.push(data.result[duplicateTownCity[j]]);
                                    }
                                }

                                courses.sort((a, b) => {
                                    if (a.start_date && b.start_date) {
                                        return a.start_date.date.localeCompare(b.start_date.date);
                                    }
                                })

                                let courseMarker = new google.maps.Marker({
                                    position: { lat: course.latitude, lng: course.longitude },
                                    title: course.towncity,
                                    animation: google.maps.Animation.DROP,
                                    icon: {
                                        path: 'M19.851,57.81C3.108,33.537,0,31.046,0,22.125a22.125,22.125,0,1,1,44.25,0c0,8.921-3.108,11.412-19.851,35.685a2.767,2.767,0,0,1-4.548,0Z',
                                        fillColor: (course.colour ? course.colour : '#ea8a4b'),
                                        fillOpacity: 1,
                                        anchor: new google.maps.Point(20, 60),
                                        strokeWeight: 1,
                                        strokeColor: '#ffffffaa',
                                        scale: 0.7,
                                        data: course,
                                    },
                                    label: { text: courses.length.toString(), className: 'course-group-label', color: '#fff', fontWeight: '700', fontFamily: '"Montserrat", sans-serif' }
                                });

                                courseMarker.addListener('click', () => {
                                    showCourseGroupModal(courses)
                                });

                                courseMarker.setMap(gLocationMap);
                                locationMarkers.push(courseMarker);
                            } else {
                                let courseMarker = new google.maps.Marker({
                                    position: { lat: course.latitude, lng: course.longitude },
                                    title: course.post_title,
                                    animation: google.maps.Animation.DROP,
                                    icon: {
                                        path: 'M19.851,57.81C3.108,33.537,0,31.046,0,22.125a22.125,22.125,0,1,1,44.25,0c0,8.921-3.108,11.412-19.851,35.685a2.767,2.767,0,0,1-4.548,0Z',
                                        fillColor: (course.colour ? course.colour : '#ea8a4b'),
                                        fillOpacity: 1,
                                        anchor: new google.maps.Point(20, 60),
                                        strokeWeight: 1,
                                        strokeColor: '#ffffffaa',
                                        scale: 0.7,
                                        data: course,
                                    },
                                });

                                courseMarker.addListener('click', () => {
                                    showCourseModal (course);
                                });

                                courseMarker.setMap(gLocationMap);
                                locationMarkers.push(courseMarker);
                            }
                        }
                    }

                    // Zoom map
                    if (data.coordinates && location) {
                        gLocationMap.panTo(data.coordinates);
                        gLocationMap.setZoom(10);
                    }
                });
            }

            function getIndexesOfValue(array, value) {
                let indexes = [];

                for (let index = 0; index < array.length; index++) {
                    if (array[index] === value) {
                        indexes.push(index)
                    }
                }

                return indexes;
            }

            // Search directories
            const directoryLocationSearch = document.getElementById('directory-location-search-form');
            if (directoryLocationSearch) {
                directoryLocationSearch.addEventListener('submit', (e) => {
                    e.preventDefault();

                    let keywords = directoryLocationSearch.querySelector('input[name="location"]');
                    if (!keywords && !keywords.value) {
                        console.error('Missing location input');
                        return;
                    }

                    return getDirectoryListingsForMap(keywords.value);
                });

                const directoryLocationSearchInput = directoryLocationSearch.querySelector('input[type="text"]');
                if (directoryLocationSearchInput) {
                    const autocomplete = new google.maps.places.Autocomplete(directoryLocationSearchInput, {
                        types: ['geocode']
                    });
                    google.maps.event.addListener(autocomplete, 'place_changed', function(selected) {
                        let place = autocomplete.getPlace();
                        if (place && place.formatted_address && place.geometry.location) {
                            getDirectoryListingsForMap(place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng(), place);
                        }
                    });
                }
            }


            // Fetch directory for map
            function getDirectoryListingsForMap(location, lat, lng, place) {

                // Clear previous markers
                /*if (locationMarkers) {
                    for (let i = 0; i < locationMarkers.length; i++) {
                        locationMarkers[i].setMap(null);
                    }
                }*/

                // Coordinates are optional
                if (!lat) lat = null;
                if (!lng) lng = null;
                if (!place) place = null;

                let values = {}

                let url = '/wp-json/directories/v1/get/';

                if (location) values.location = location;
                if (lat) values.lat = lat;
                if (lng) values.lng = lng;

                let encodedValues = new URLSearchParams(values).toString();

                url += '?' + encodedValues

                fetch(url, {
                    method: 'GET',
                })
                .then(response => response.json())
                .then(data => {

                    if (!data.success && data.error) {
                        return alert(data.error);
                    }

                    if (data.success && data.result) {

                        if (!locationMarkers.length) {
                            for (let i = 0; i < data.result.length; i++) {
                                let listing = data.result[i];

                                if (isNaN(listing.latitude) || isNaN(listing.longitude)) {
                                    continue;
                                }

                                // Only add markers on initial load. Markers are now never removed.
                                let directoryMarker = new google.maps.Marker({
                                    position: { lat: listing.latitude, lng: listing.longitude },
                                    title: listing.post_title,
                                    animation: google.maps.Animation.DROP,
                                    icon: {

                                        path: 'M19.851,57.81C3.108,33.537,0,31.046,0,22.125a22.125,22.125,0,1,1,44.25,0c0,8.921-3.108,11.412-19.851,35.685a2.767,2.767,0,0,1-4.548,0Z',
                                        fillColor: '#ea8a4b',
                                        fillOpacity: 1,
                                        anchor: new google.maps.Point(20, 60),
                                        strokeWeight: 1,
                                        strokeColor: '#ffffffaa',
                                        scale: 0.7,
                                        data: listing,
                                    },
                                });

                                directoryMarker.addListener('click', () => {
                                    showDirectoryModal (listing);
                                });

                                directoryMarker.setMap(gLocationMap);
                                locationMarkers.push(directoryMarker);
                            }
                        }
                    }

                    // Lat/lng already set from autocomplete lookup? Pan map to there as it's more reliable
                    if (lat && lng) {
                        const coords = new google.maps.LatLng(lat, lng);
                        gLocationMap.panTo(coords);

                        let mapZoom = 10;
                        if (place && place.types) {

                           switch (place.types[0]) {
                                case "continent":
                                    mapZoom = 4;
                                    break;

                                case "country":
                                    mapZoom = 5;
                                    break;

                                case "locality":
                                    mapZoom = 11;
                                    break;

                                default:
                                    mapZoom = 10;
                           }
                        }

                        gLocationMap.setZoom(mapZoom);

                    } else {

                        // Zoom map
                        if (data.coordinates && location) {
                            const coords = new google.maps.LatLng(data.coordinates[0], data.coordinates[1]);
                            gLocationMap.panTo(coords);
                            gLocationMap.setZoom(10);
                        }
                    }
                });
            }

        });

        // Click course
        let openCourseModalBtns = document.querySelectorAll('.js-open-event-online');
        openCourseModalBtns.forEach(function(btn) {

            btn.addEventListener('click', (e) => {
                e.preventDefault();

                btn.classList.add('loading');

                let course_id = btn.getAttribute('data-id');
                fetch('/wp-json/courses/v1/get/?id=' + course_id, {
                    method: 'GET',
                })
                .then(response => response.json())
                .then(data => {

                    if (data.success && data.result) {
                        showCourseModal (data.result);
                    } else {
                        alert('Unable to open course.');
                    }
                });
            });
        });


        const courseGroupModal = document.getElementById('course-group-modal');
        let courseGroupModalModal;

        if (courseGroupModal) {
            courseGroupModalModal = new Modal(courseGroupModal, {
                keyboard: false,
            });
        }

        // Show course modal
        function showCourseModal(course, courses = false) {
            let courseModal = document.getElementById('course-modal');

            if (!courseModal) {
                return;
            }

            courseGroupModalModal.hide();

            const closeArrow = document.getElementById('data-course-close-arrow');
            const closeButton = document.getElementById('data-course-close');

            if (courses === false) {
                closeArrow.classList.add('d-none');
                closeButton.classList.remove('d-none');
            } else {
                closeArrow.classList.remove('d-none');
                closeButton.classList.add('d-none');

                closeArrow.addEventListener('click', () => {
                    const modal = Modal.getInstance(document.getElementById('course-modal'));
                    modal.hide();
                    showCourseGroupModal(courses);
                });
            }

            document.getElementById('data-course-title').innerHTML  = course.post_title;
            document.getElementById('data-course-date').innerHTML   = course.date_string;
            document.getElementById('data-course-time').innerHTML   = course.time_string;

            if (course.venue) {
                document.getElementById('data-course-venue').innerHTML  = course.venue;
                document.getElementById('data-course-venue').parentElement.classList.remove('d-none');
            } else {
                document.getElementById('data-course-venue').parentElement.classList.add('d-none');
            }

            if (course.email_address) {
                document.getElementById('data-course-email').innerHTML  = course.email_address;
                document.getElementById('data-course-email').parentElement.classList.remove('d-none');
            } else {
                document.getElementById('data-course-email').parentElement.classList.add('d-none');
            }

            if (course.phone_number) {
                document.getElementById('data-course-phone').innerHTML  = course.phone_number;
                document.getElementById('data-course-phone').parentElement.classList.remove('d-none');
            } else {
                document.getElementById('data-course-phone').parentElement.classList.add('d-none');
            }

            if (course.organiser) {
                document.getElementById('data-course-organiser').innerHTML  = course.organiser;
                document.getElementById('data-course-organiser').parentElement.classList.remove('d-none');
            } else {
                document.getElementById('data-course-organiser').parentElement.classList.add('d-none');
            }

            if (course.number_of_sessions) {
                document.getElementById('data-course-sessions').innerHTML  = course.number_of_sessions;
                document.getElementById('data-course-sessions').parentElement.classList.remove('d-none');
            } else {
                document.getElementById('data-course-sessions').parentElement.classList.add('d-none');
            }

            if (course.post_content) {
                document.getElementById('data-course-details').parentElement.classList.remove('d-none');
                document.getElementById('data-course-details').innerHTML  = course.post_content;
            } else {
                document.getElementById('data-course-details').parentElement.classList.add('d-none');
            }

            if (course.extra_info) {
                document.getElementById('data-course-extrainfo').parentElement.classList.remove('d-none');
                document.getElementById('data-course-extrainfo').innerHTML  = course.extra_info;
            } else {
                document.getElementById('data-course-extrainfo').parentElement.classList.add('d-none');
            }

            // Update link
            if (course.booking_url) {
                document.getElementById('data-course-url').classList.remove('d-none');
                document.getElementById('data-course-url').setAttribute('href', course.booking_url);
            } else {
                document.getElementById('data-course-url').classList.add('d-none');
            }

            courseModal = new Modal(document.getElementById('course-modal'), {
                keyboard: false,
            });
            courseModal.show();
        }

        function showCourseGroupModal(courses) {
            let courseGroupModal = document.getElementById('course-group-modal');

            if (!courseGroupModal) {
                return;
            }

            const blank = document.getElementById('data-course-group-modal-blank-title');
            const parent = document.getElementById('data-course-group-container');
            const previous = document.querySelectorAll('.data-course-group-modal-title')

            previous.forEach(element => {
                element.classList.remove('d-block');
                element.classList.add('d-none');
            })

            for (let i = 0; i < courses.length; i++) {
                let div = document.getElementById(`data-course-group-title-${i}`)

                if (!div) {
                    div = blank.cloneNode(true);
                }

                div.id = `data-course-group-title-${i}`;
                div.innerHTML = courses[i].post_title;
                div.classList.add('d-block');
                div.classList.remove('d-none');

                parent.appendChild(div);

                div.addEventListener('click', e => {
                    e.preventDefault();
                    showCourseModal(courses[i], courses);
                });
            };

            courseGroupModalModal.show();
        }

        // Click directory listing
        let openDirectoryModalBtns = document.querySelectorAll('.js-open-event');
        openDirectoryModalBtns.forEach(function(btn) {

            btn.addEventListener('click', (e) => {
                e.preventDefault();

                btn.classList.add('loading');

                let course_id = btn.getAttribute('data-id');
                fetch('/wp-json/directories/v1/get/?id=' + course_id, {
                    method: 'GET',
                })
                .then(response => response.json())
                .then(data => {

                    if (data.success && data.result) {
                        showDirectoryModal (data.result);
                    } else {
                        alert('Unable to open directory listing.');
                    }
                });
            });
        });


        // Show directory modal
        function showDirectoryModal(listing) {

            let listingModal = document.getElementById('directory-modal');

            if (!listingModal) {
                return;
            }

            document.getElementById('data-listing-title').innerHTML  = listing.post_title;

            // Address
            if (listing.full_address) {
                document.getElementById('data-listing-details').parentElement.classList.remove('d-none');
                document.getElementById('data-listing-details').innerHTML  = listing.full_address;
            } else {
                document.getElementById('data-listing-details').parentElement.classList.add('d-none');
            }

            // Contact details
            if (listing.contact_name || listing.contact_phone || listing.contact_email) {
                document.getElementById('data-listing-contact').classList.remove('d-none');

                if (listing.contact_name) {
                    document.getElementById('data-listing-contact-name').classList.remove('d-none');
                    document.getElementById('data-listing-contact-name').innerHTML = listing.contact_name;
                } else {
                    document.getElementById('data-listing-contact-name').classList.add('d-none');
                }

                if (listing.contact_phone) {
                    document.getElementById('data-listing-contact-phone').classList.remove('d-none');
                    document.getElementById('data-listing-contact-phone').innerHTML = '<a href="tel:'+ listing.contact_phone +'">'+ listing.contact_phone +'</a>';
                } else {
                    document.getElementById('data-listing-contact-phone').classList.add('d-none');
                }

                if (listing.contact_email) {
                    document.getElementById('data-listing-contact-email').classList.remove('d-none');
                    document.getElementById('data-listing-contact-email').innerHTML = '<a href="mailto:'+ listing.contact_email +'">'+ listing.contact_email +'</a>';
                } else {
                    document.getElementById('data-listing-contact-email').classList.add('d-none');
                }
            } else {
                document.getElementById('data-listing-contact').classList.add('d-none');
            }

            // Children age range
            if (listing.children_age_range) {
                document.getElementById('data-listing-agerange').classList.remove('d-none');
                document.getElementById('data-listing-agerange').innerHTML = listing.children_age_range;
            } else {
                document.getElementById('data-listing-agerange').classList.add('d-none');
            }

            // Schedule
            if (listing.schedule) {
                document.getElementById('data-listing-schedule').classList.remove('d-none');
                document.getElementById('data-listing-schedule').innerHTML = listing.schedule;
            } else {
                document.getElementById('data-listing-schedule').classList.add('d-none');
            }

            // Description
            if (listing.post_content) {
                document.getElementById('data-listing-description').classList.remove('d-none');
                document.getElementById('data-listing-description').innerHTML = listing.post_content;
            } else {
                document.getElementById('data-listing-description').classList.add('d-none');
            }

            // Update link
            if (listing.website) {
                document.getElementById('data-listing-website').classList.remove('d-none');
                document.getElementById('data-listing-website').setAttribute('href', listing.website);
            } else {
                document.getElementById('data-listing-website').classList.add('d-none');
            }

            // Facebook
            if (listing.facebook) {
                document.getElementById('data-listing-facebook').classList.remove('d-none');
                document.getElementById('data-listing-facebook').setAttribute('href', listing.facebook);
            } else {
                document.getElementById('data-listing-facebook').classList.add('d-none');
            }

            // Twitter
            if (listing.twitter) {
                document.getElementById('data-listing-twitter').classList.remove('d-none');
                document.getElementById('data-listing-twitter').setAttribute('href', listing.twitter);
            } else {
                document.getElementById('data-listing-twitter').classList.add('d-none');
            }

            listingModal = new Modal(document.getElementById('directory-modal'), {
                keyboard: false,
            });
            listingModal.show();
        }

        // Show more products
        document.querySelectorAll('.js-show-more').forEach(btn => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();

                let toggleId = btn.getAttribute('data-toggle');
                let toggleContainer = document.getElementById(toggleId);
                if (toggleContainer) {
                    toggleContainer.classList.add('show-all');
                }

                btn.parentNode.classList.add('d-none');
            });
        });

        // Show more podcasts
        document.querySelectorAll('.js-show-more-podcasts').forEach(btn => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();

                let container = btn.closest('.expandable-container');
                let parent = container.querySelectorAll('.episode-list')[0];

                if (parent) {
                    parent.classList.remove('hidden-items');
                }

                btn.classList.add('d-none');
            });
        });

        // Show the event modal
        function showEventModal(event) {

            if ((typeof event.start == 'string') && typeof moment == 'function') {
                event.start = moment(event.start);
            }

            $('#modal-title').html(event.title);
            $('#data-event-date').html(event.start.format('LL'));
            $('#data-event-time').html(event.start.format('h:mm a'));

            $('#data-event-details').html(event.description);
            if (!event.description) {
                $('#data-event-details').parent('p').hide();
            } else {
                $('#data-event-details').parent('p').show();
            }

            $('#data-event-venue').html(event.venue);
            if (!event.venue) {
                $('#data-event-venue').parent('p').hide();
            } else {
                $('#data-event-venue').parent('p').show();
            }


            if (!event.price || event.price == '0.00') {
                $('#data-event-price').html('Free');
            } else {

                if (event.lowest_price && (event.price !== event.lowest_price)) {
                    $('#data-event-price').html('From &pound;' + event.lowest_price);
                } else {
                    $('#data-event-price').html('&pound;' + event.price);
                }
            }

            $('#data-event-url').attr('href', event.target_url);
            if (!event.target_url) {
                $('#data-event-url').hide();
            } else {
                $('#data-event-url').show();
            }

            $('#data-event-download').attr('href', event.document_url);
            if (!event.document_url) {
                $('#data-event-download').hide();
            } else {
                $('#data-event-download').show();
            }

            let eventModal = document.getElementById('event-modal');

            if (eventModal) {
                eventModal = new Modal(eventModal, {
                    keyboard: false,
                });
                eventModal.show();
            }
        }

        // Fix to hide accordion
        document.querySelectorAll('.accordion-button').forEach(function(button) {
            button.addEventListener('click', function(e) {

                    let collapsed = document.querySelectorAll(button.getAttribute('data-bs-target'))[0];

                    if (collapsed && collapsed.classList.contains('show-item')) {
                        e.preventDefault();

                        collapsed.classList.remove('show-item');
                        button.setAttribute('aria-expanded', 'false');
                        return;
                    } else {
                        collapsed.classList.add('show-item');
                    }

            });
        });


        // Jquery full calendar
        (function($) {

            let $mobileListingContainer = $('#mobile-calendar-listings');
            let $showMoreBtnContainer   = $('#btn-show-more-container');



            if ($('#event_calendar').length) {
                jQuery('#event_calendar').fullCalendar({
                    timeFormat: 'h:mm a',
                    initialView: 'dayGridMonth',
                    fixedWeekCount: false,
                    contentHeight: 'auto',
                    header: {
                        left: 'prevYear, prev',
                        center: 'title',
                        right: 'next, nextYear',
                    },
                    eventSources: [{
                        url: '/wp-json/events/v1/get?return_data_only=1&type=online',
                        success: function(data) {
                            $mobileListingContainer.html('');

                            if (data.length) {
                                data.forEach(function(event) {
                                    var $template = $('.mobile-calendar-template').clone();

                                    if ((typeof event.start == 'string') && typeof moment == 'function') {
                                        event.start = moment(event.start);
                                    }

                                    event.start_day = event.start.format('dddd');
                                    event.start_time = event.start.format('h:mma');

                                    $template.find('.cal-list-day').html(event.start_dayofweek);
                                    $template.find('.cal-list-date').html(event.start.format('D'));
                                    $template.find('.cal-list-title').html(event.title);
                                    $template.find('.cal-list-time').html(event.start_time);
                                    $template.find('.cal-list-url').attr('data-event', JSON.stringify(event));

                                    $mobileListingContainer.append($template.html());
                                });
                            }

                            if (data.length > 5) {
                                $showMoreBtnContainer.removeClass('d-none')
                            } else {
                                $showMoreBtnContainer.addClass('d-none');
                            }
                        },
                    }],
                    eventClick: function(event) {
                        return showEventModal(event);
                    },
                    viewRender: function(view) {

                        // Update the year attribute on the month list
                        $('.js-change-month').attr('data-year', view.intervalStart.format('Y'));
                    },
                });
            }

            // Show all events
            $(document).on('click', '#btn-show-more-container .btn', function(e) {
                e.preventDefault();

                let $btn = $(this);

                if ($mobileListingContainer.hasClass('show-all')) {
                    $mobileListingContainer.removeClass('show-all');
                    $btn.find('span').html('Show more');
                } else {
                    $mobileListingContainer.addClass('show-all');
                    $btn.find('span').html('Show less');
                }

            });

            // Click the mobile event list item to show modal
            $(document).on('click', '.js-open-event-modal', function(e) {
                e.preventDefault();

                var eventJson = $(this).attr('data-event');
                var event = JSON.parse(eventJson);
                return showEventModal(event);
            });

            // Select calendar date
            $('.js-change-month').click(function(e) {
                e.preventDefault();

                let month    = $(this).attr('data-month');
                let year     = $(this).attr('data-year');
                let goToDate = year + '-' + month + '-01';
                $('#event_calendar').fullCalendar('gotoDate', goToDate);

                // Change button state
                $('.js-change-month.active').removeClass('active');
                $(this).addClass('active');
            });

            // Select calendar year
            $('.js-change-year').click(function(e) {
                e.preventDefault();

                let year = parseInt($(this).attr('data-year'));
                if ($(this).attr('data-direction') == 'prev') {
                    year = year - 1;
                } else {
                    year = year + 1;
                }

                let goToDate = year + '-01-01';

                $('#event_calendar').fullCalendar('gotoDate', goToDate);

                // Change button state
                $('.js-change-month.active').removeClass('active');
                $('.js-change-month[data-month="01"]').addClass('active');

                $('.calendar__months-dir--prev').removeClass('disabled');
                $('.calendar__months-dir--next').removeClass('disabled');

                if (year < (new Date().getFullYear())) {
                    $('.calendar__months-dir--prev').addClass('disabled');
                } else if (year > new Date().getFullYear()) {
                    $('.calendar__months-dir--next').addClass('disabled');
                }

                // Update year forward and back buttons
                $('.calendar__months-dir--next').attr('data-year', year);
                $('.calendar__months-dir--prev').attr('data-year', year);
            });

            // Select 2
            $('.select2-filter-input').select2({
                minimumResultsForSearch: -1,
            }).on('change', function (elem) {

                // If input is the category input
                var $input = $('#' + elem.currentTarget.id);
                if ($input.attr('id') == 'order-form-category') {

                    // Fetch related topics
                    let topics = $input.find('option[value="'+ $input.val() +'"]').attr('data-topics');
                    if (topics) {
                        topics = JSON.parse(topics);
                    }
                    updateTopicsDropdown(topics);
                }

                $('#woocommerce-order-form').attr('data-page-num', 1);

                if ($('#woocommerce-order-form').attr('data-autosubmit')) {
                    $('#woocommerce-order-form').submit();
                }
            });

            // Change other input
            $('.js-submit-on-change').change(function() {
                $('#woocommerce-order-form').attr('data-page-num', 1);
                $('#woocommerce-order-form').submit();
            });

            let $loadMore = $('#load-more-products');
            $('.load-more-products').click(function(e) {
                e.preventDefault();

                let nextPageNum = parseInt($('#woocommerce-order-form').attr('data-page-num')) + 1;

                $('#woocommerce-order-form').attr('data-page-num', nextPageNum);
                $('#woocommerce-order-form').submit();
            });

            // Update the topic dropdown
            function updateTopicsDropdown(newTopics) {

                let $topicInput = $('#order-form-tag');
                if ($topicInput) {

                    let options = '<option value="">All Topics</option>';
                    jQuery.each(newTopics, function(key, data) {
                        options += '<option value="'+ data.term_id +'">'+ data.name +'</option>';
                    });

                    $topicInput.html(options);
                    $topicInput.select2({
                        minimumResultsForSearch: -1,
                    });
                }
            }

            // Submit ordering/filter form for shop
            $(document).on('submit', '#woocommerce-order-form', function(e) {
                e.preventDefault();

                let $form       = $(this);
                let targetId    = $form.attr('data-target');
                let $loader     = $form.find('.loader');
                let $target     = $('#' + targetId);
                let $loadMore   = $('#' + $target.attr('data-btn-more-target'));
                let pageNumber  = parseInt($form.attr('data-page-num'));
                let pageLimit   = 12;
                let consumerKey = $form.attr('data-consumer');
                let secretKey   = $form.attr('data-secret');

                // Show loader
                $loader.addClass('show');

                // Data
                let values = {
                    status: 'publish',
                    min_price: 0.00,
                    page: pageNumber,
                    hide_empty: false
                };
                let categoryIds = [];

                // Category
                let $category = $form.find('select[name="category"]');
                if ($category.val()) {
                    if ($category.val() != 'all') {

                        categoryIds.push(parseInt($category.val()));

                        // Add children
                        if ($category.find('option:selected').attr('data-children')) {
                            let childCategories = JSON.parse($category.find('option:selected').attr('data-children'));
                            childCategories.forEach((item, index) => {
                                categoryIds.push(item);
                            });
                        }
                    }
                }

                let $includeFaith = $form.find('input[name="include_faith"]');
                if (!$includeFaith.is(':checked')) {
                    values.category_exclude = $includeFaith.val();
                    values.include_faith_resources = true;
                }

                if (categoryIds.length) {
                    values.categories = categoryIds.join('+');
                }

                // Tags
                let $tag = $form.find('select[name="tag"]');
                if ($tag.val()) {
                    values.tag = $tag.val();
                }

                // Order by price
                let $orderPrice = $form.find('select[name="orderby_price"]');
                if ($orderPrice.val()) {
                    values.orderby = 'price';
                    values.order = $orderPrice.val() == 'asc' ? 'asc' : 'desc';
                }

                // Order by date
                let $orderDate = $form.find('select[name="orderby_date"]');
                if ($orderDate.val()) {
                    values.orderby_date = 'date';
                    values.order_date = $orderDate.val() == 'asc' ? 'asc' : 'desc';
                }

                // Data layer
                window.dataLayer = window.dataLayer || [];
                let productDataLayer = [];

                $.ajax({
                    type: 'GET',
                    dataType: 'json',
                    url: '/wp-json/wc/v3/products?product_filter=true&consumer_key='+ consumerKey +'&consumer_secret='+ secretKey +'&per_page=' + pageLimit,
                    data: values,
                    success: function(data) {

                        // Hide loader
                        $loader.removeClass('show');

                        if ($target) {

                            // Reset results?
                            if (pageNumber == 1) {
                                $target.html('');
                                $loadMore.addClass('d-none');
                            }

                            // Any results?
                            if ((data.length == 0) && (pageNumber == 1)) {
                                $target.html('<p class="woocommerce-info mb-5">No products were found matching your selection.</p>');
                                $loadMore.addClass('d-none');
                            } else {

                                $target.append('<ul class="products columns-4"></ul>');

                                // Re-init
                                productDataLayer = [];

                                let count = 0;
                                $.each(data, function(i, item) {

                                    let $product = $('#product-template .block--product').clone();

                                    $product.find('[data-elem="title"]').html(item.name);
                                    $product.find('[data-elem="price"]').html(item.price_html);
                                    $product.find('[data-elem="url"]').attr('href', item.permalink);

                                    if (item.images.length) {
                                        $product.find('[data-elem="image"]').html('<div class="resp-img"><div class="image__resp"><picture><img data-src="'+ item.images[0].src +'" class="lazyload"></picture></div></div>');
                                    }

                                    $target.find('ul:last-child').append($product);

                                    let last = '';
                                    if (count == 3) {
                                        last = 'last';
                                        count = 0;
                                    } else {
                                        count++;
                                    }

                                    // Wrap in list tags
                                    $product.wrap('<li class="product type-product post-'+ item.id +' status-publish '+ item.stock_status +' shipping-taxable purchasable product-type-simple '+ last +'"></li>');

                                    // Push to product array for data layer
                                    let categoryArray = [];
                                    item.categories.forEach(function(cat, i) {
                                        categoryArray.push(cat.name);
                                    })
                                    productDataLayer.push({
                                        name: item.name,
                                        id: item.sku,
                                        price: item.price,
                                        category: categoryArray.join('/'),
                                        position: (i + 1)
                                    });

                                    // Attach product data
                                    $product.find('a').attr('data-product-data', JSON.stringify({
                                        name: item.name,
                                        sku: item.sku,
                                        price: item.price,
                                        category: categoryArray.join('/'),
                                        position: (i + 1)
                                    }));
                                    $product.find('a').attr('data-currency', 'GBP');
                                });

                                // Push content to data layer
                                window.dataLayer.push({
                                    'event': 'product_impression',
                                    'ecommerce': {
                                        'currencyCode': 'GBP',
                                        'impressions': productDataLayer,
                                    }
                                });

                                // Show load more
                                if (data.length < pageLimit) {
                                    $loadMore.addClass('d-none');
                                } else {
                                    $loadMore.removeClass('d-none');
                                }

                                // Load images
                                loadLazy();
                            }
                        }

                    },
                    error: function(jqXHR, exception) {
                        console.log('Form AJAX error', jqXHR);
                        console.error('Form AJAX error', exception);
                        $loader.removeClass('show');
                    },
                });
            });


            // Change donation tab
            $('.donation-form .tablist .nav-item > button').click(function() {
                let $button     = $(this);
                let $tabContent = $('#donate-controls');//$('' + $button.attr('data-bs-target'));
                let $form       = $button.parents('form');
                let action      = $button.attr('data-action');
                let textExt     = $button.attr('data-text-ext');

                let defaultVal  = $button.attr('data-default');

                if ($button.attr('id') === "donate-monthly-tab") {
                    $('#js-donation-increase').removeClass('d-none');
                } else {
                    $('#js-donation-increase').addClass('d-none');
                }

                $form.attr('action', action);

                $form.find('input[name="amount"]').remove();

                let $otherContainer = $tabContent.find('.content-container').find('.other-donation-amount');
                $otherContainer.html('<input type="text" class="form-input" name="amount" placeholder="e.g &pound;100" value="30"/>');
                $tabContent.find('.js-select-donation-amount[data-val="30"]').trigger('click');
                $form.parents('.section--donation').find('.donation-image__content .figure__ext').html(textExt);

                // Change to default value
                $('.js-select-donation-amount[data-key="'+ defaultVal +'"]').trigger('click');
                if (donationSlider) {
                    donationSlider.slideTo(3);
                }
            })

            // Select donation amount
            $('.js-select-donation-amount').click(function(e) {
                e.preventDefault();

                let $button = $(this);
                let $li = $button.parent('li');
                let $ul = $li.parent('ul');
                let $container = $ul.parent('.donation-swiper').next('.content-container');
                let $otherContainer = $container.find('.other-donation-amount');
                let $valueInput = $otherContainer.find('input[name="amount"]');
                let value = $button.attr('data-val');
                let key = $button.attr('data-key');

                // Switch amount
                $ul.find('li.active').removeClass('active');
                $li.addClass('active');

                // Other value selected?
                if (value == 'other') {
                    $otherContainer.addClass('show');
                    $valueInput.val('');
                    $valueInput.attr('required', 'required');

                } else {
                    $otherContainer.removeClass('show');
                    $valueInput.val(value);
                    $valueInput.attr('required', 'false');
                }

                // Show image
                $('.donation-image__container').removeClass('show');
                let $donationImage = $('.donation-image__container[data-id="'+ key +'"]');
                $donationImage.addClass('show');
            });

            // Show video
            $('.js-play-video').click(function(e) {
                e.preventDefault();

                let videoUrl = $(this).attr('data-url');

                if (videoUrl) {

                    // Manipulate video url
                    videoUrl = videoUrl.replace('watch', 'embed');
                    videoUrl = videoUrl.replace('?v=', '/');
                    videoUrl += '?autoplay=1&rel=0';

                    let $videoModalContent = $('#video-modal');
                    $videoModalContent.find('.modal-body').html('<iframe src="'+ videoUrl +'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');

                    // let videoModal = new Modal($videoModalContent, {
                    //     keyboard: false,
                    // });
                    // videoModal.show();

                    if ($videoModalContent) {
                        const videoModal = new Modal($videoModalContent, {
                            keyboard: false,
                        });
                        videoModal.show();
                    }
                } else {
                    console.error('No URL specified!!');
                }
            });

            // Close video
            $('.js-close-video').click(function(e) {
                e.preventDefault();

                $(this).parents('.modal').find('iframe').remove();
            });

            // Recalculte title heights
            function recalculateTitleHeights() {

                let forceHeightTitles = document.querySelectorAll('.force-title-height');
                forceHeightTitles.forEach(function(block) {

                    let maxHeight = 0;
                    let titles = block.querySelectorAll('.block--basic__title h6, h3.title');
                    if (titles.length) {
                        titles.forEach(function(title) {

                            title.style.removeProperty('height');
                            if (title.offsetHeight > maxHeight) {
                                maxHeight = title.offsetHeight;
                            }
                        });

                        // Only add uniform height on larger devices
                        if ($(window).width() >= 768) {
                            let titleBlocks = block.querySelectorAll('.block--basic__title h6, h3.title');
                            titleBlocks.forEach(function(titleBlock) {
                                titleBlock.style.height = maxHeight + 'px';
                            });
                        }
                    }
                });
            }
            $('.js-calculate-title-heights').click(function() {
                setTimeout(function() {
                    recalculateTitleHeights();
                }, 230);
            });

            $(window).on("resize", function() {
                if ($(window).width() >= 768) {
                    recalculateTitleHeights();
                } else if ($(window).width() < 768) {
                    let forceHeightTitles = document.querySelectorAll('.force-title-height');
                    forceHeightTitles.forEach(function(block) {
                        let titleBlocks = block.querySelectorAll('.block--basic__title h6, h3.title');
                        titleBlocks.forEach(function(titleBlock) {
                            titleBlock.style.height = 'initial';
                        });
                    });
                }
            });

            // Run on load
            recalculateTitleHeights();
        }) (jQuery);

        document.querySelectorAll('#blog-archive-tab .nav-link').forEach(function(link) {
            link.addEventListener('click', function(e) {
                let $link = $(this);
                let $id = $link.attr('id');

                // add id to the url
                window.history.pushState("", "", '?tab=' + $id);
            });
        });


        // Donation form logic
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var amount = urlParams.get("amount");

        if(amount != null){
            jQuery(".donate-amount input[type=text]").val(parseFloat(amount).toFixed(2));
        }

        (function($) {
            $(document).ready(function() {

                function calculateGiftAid() {
                    var GIFT_AID_RATE = 0.25;

                    var value = 0;
                    if ($('.ginput_amount').length) {
                        value = $('.ginput_amount').val();
                    }
                    else if ($('.giftaid-amount input').length && $('.giftaid-amount input').val()) {
                        value = $('.giftaid-amount input').val();
                    }
                    else if ($('.giftaid-select select option:selected').val() != 0) {
                        value = $('.giftaid-select select option:selected').val();
                    }
                    else if ($('.gfield_price :selected').val() != 0) {
                        value = $('.gfield_price :selected').val();
                    }

                    if (value && value.indexOf('£') === 0) {
                        value = value.substring(1, value.length);
                    }

                    if (!value) {
                        value = 0;
                    }

                    var donation = parseFloat(value);

                    if (isNaN(donation)) {
                        donation = 0.00;
                    }

                    var giftaid = donation * GIFT_AID_RATE;

                    $('.giftaid-donation').html(donation.toFixed(2));
                    $('.giftaid-value').html(giftaid.toFixed(2 ));
                }

                setTimeout(function() {
                    calculateGiftAid();
                }, 1000)

                $(document).on('change', '.donate-amount input', function() {
                    calculateGiftAid();
                })
            });
        })(jQuery);

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
