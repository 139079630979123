// import external dependencies
import 'jquery';
import './track';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import courses from './routes/courses';
import checkout from './routes/checkout';

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    // Home page
    home,
    // Courses
    courses,
    // Checkout
    checkout,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
